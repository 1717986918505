
module.exports={
    // for test
    URL :'https://snsm-test.exceloid.in/nsm-example/graphql',
    Auth : 'https://snsm-test.exceloid.in/nsm-authserver/oauth/token',
    DOWNLOADURL :'https://snsm-test.exceloid.in:8443/',

    // For Production

    // URL :'https://nsm-api.exceloid.in/nsm-example/graphql',
    // Auth : 'https://nsm-api.exceloid.in/nsm-authserver/oauth/token',
    // DOWNLOADURL :'https://nsm-api.exceloid.in:8443/',
}